<template>
  <div class="container">
    <div class="ui-border-line"></div>

    <template v-if="!pageOptions.hiddenSearchConditionController && !query.recommGroupUid && $route.path === '/artist/list'">
      <div class="list-option">
        <div @click="() => $refs.SortModal.show()" class="ui-btn">
          {{sortText}}
          <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
        </div>
        <div @click="() => gotoSearch()" class="ui-btn">
          상세 검색
          <icon class="icon" src="img/common/tab-unfold.png" width="1.2rem" height="5.5rem" :cover="false" />
        </div>
      </div>
      <div class="ui-border-line"></div>
    </template>

    <div class="scroll-container" ref="container">
      <a-list :data="artistData" v-if="!init || artistData.length" />
      <blank-search v-else />
    </div>

    <modal
      ref="SortModal"
      :show-cb="() => modalData.sort = artistListOpt.sort"
      :confirm-cb="(modal) => {
        modal.hide()
        artistListOpt.sort = modalData.sort
        let newQuery = JSON.parse(JSON.stringify(query))
        $router.replace({
          query: Object.assign(newQuery, { sort: modalData.sort })
        }, () => {
          getArtistData(true)
        })
      }"
    >
      <ul slot="body">
          <li
            class="text-center"
            v-for="(row, key) in $config.constant.artistSort"
            :key="key"
            @click="modalData.sort = key"
            :class="{ on: modalData.sort === key }"
          >
            {{row}}
            <div class="ui-border-line"></div>
          </li>
        </ul>
    </modal>
  </div>
</template>

<script>
import AList from '@/components/artist/AList'
import Modal from '@/components/common/Modal'
import Icon from '@/components/common/Icon'
import BlankSearch from '@/components/blank/BlankList'

export default {
  name: 'ArtistList',
  components: {
    AList,
    Modal,
    Icon,
    BlankSearch,
  },
  data() {
    return {
      init: false,
      artistDataTotal: 0,
      artistData: [],
      artistListOpt: {
        category: 'all',
        limit: 20,
        sort: 'listNum'
      },
      modalData: {
        sort: 'listNum'
      },
      offset: {
        artistData: 0,
      },
      que: {
        artistData: false,
      },
      scroll: {
        lastY: 0,
      },
    }
  },
  computed: {
    pageOptionKeys: {
      get() {
        return [
          'disableResetBackHistory',
          'hiddenSearchConditionController',
          'resetDataOnBack',
        ]
      },
      cache: false,
    },
    pageOptions: {
      get() {
        const pageOptions = {}

        this.pageOptionKeys.forEach(key => {
          switch (key) {
            case 'disableResetBackHistory':
              pageOptions[key] = this.$route?.query?.[key] === 'true' || false
              break

            case 'hiddenSearchConditionController':
              pageOptions[key] = this.$route?.query?.[key] === 'true' || false
              break

            case 'resetDataOnBack':
              pageOptions[key] = this.$route?.query?.[key] === 'true' || false
              break

            default:
              pageOptions[key] = this.$route?.query?.[key] || null
              break
          }
        })

        return pageOptions
      },
      cache: false,
    },
    query: {
      get() {
        const nextQueryString = {}

        for (const key in this.$route.query) {
          const val = this.$route.query[key]

          if (this.pageOptionKeys.indexOf(key) === -1) {
            nextQueryString[key] = val
          }
        }

        return nextQueryString
      },
      cache: false,
    },
    sortText: {
      get() {
        return this.$config.constant.artistSort[this.artistListOpt.sort]
      }
    },
  },
  watch: {
    $route(route, nextRoute) {
      if (['ArtistList'].indexOf(route.name) === -1) {
        return
      }

      if (route.name !== nextRoute.name) {
        return
      }

      this.getArtistData(true)
    },
  },
  beforeMount() {
    this.artistListOpt.sort = this.query.sort || 'listNum'
    this.setTitle()
    this.getArtistData(true)
  },
  mounted() {
    this.$refs.container.addEventListener('scroll', this.getArtistDataScroll, false)
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      Vue.artistListOpt.sort = Vue.query.sort || 'listNum'
      Vue.setTitle()
      if (Vue.init) {
        Vue.$refs.container.scrollTop = Vue.scroll.lastY
      } else {
        Vue.getArtistData(true)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'home' || to.name === 'ArtistSearch') {
      this.reset()
    } else {
      this.scroll.lastY = this.$refs.container.scrollTop
    }

    if (this.pageOptions.resetDataOnBack) {
      this.reset()
    }

    if (this.pageOptions.disableResetBackHistory) {
      next()
    } else {
      setTimeout(() => {
        this.$store.commit('setGnbTitle', null)
        if (this.$store.state.history.back) {
          next(false)
          this.$store.commit('setHistory', { back: false })
          this.$router.push('/home')
        } else {
          next()
        }
      })
    }
  },
  methods: {
    getArtistData(init) {
      if (['ArtistList'].indexOf(this.$route.name) === -1) return
      if (this.que.artistData) return
      this.que.artistData = true

      const { category, limit, sort } = this.artistListOpt

      if (init) {
        this.offset.artistData = 0
      }

      const req = {
        method: 'post',
        url: `/artist/list/${category}/${this.offset.artistData}/${limit}/${sort}`,
        data: JSON.parse(JSON.stringify(this.query)),
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let artistData = data.response.artistData || []
            this.artistData = init ? artistData : this.artistData.concat(artistData)
            this.offset.artistData += artistData.length
            this.artistDataTotal = Number(data.response.totalCount) || 0
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.artistData = false
          }, 1000)
        })
    },
    setTitle() {
      let title = ''
      let query = this.$route.query
      if (query.genre) title = query.genre
      if (query.contents) title = query.contents
      this.$store.commit('setGnbTitle', title)
    },
    gotoSearch() {
      const router = {
        path: '/artist/search',
        query: this.$route.query,
      }
      this.$router.push(router)
    },
    getArtistDataScroll() {
      if (['ArtistList'].indexOf(this.$route.name) === -1) return

      let scrollTop = this.$refs.container.scrollTop
      let scrollHeight = this.$refs.container.scrollHeight

      if (scrollHeight <= scrollTop + window.innerHeight) {
        this.getArtistData()
      }
    },
    reset() {
      this.scroll.lastY = 0
      this.init = false
      this.artistListOpt.sort = 'listNum'
      this.artistData = []
      this.artistDataTotal = 0
      this.lastQuery = null
    }
  },
}
</script>

<style lang="scss" scoped>
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;

  .list-option {
    overflow: hidden;

    > div {
      float: left;
      width: 50%;
      text-align: left;
      color: #919191;
      color: #000;
      font-size: 1.8rem;
      line-height: 5.5rem;
      padding: 0 3rem;

      &:first-child {
        border-right: 1px solid #ececec;
      }

      .icon {
        float: right;
      }
    }
  }

  .scroll-container {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 2rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
</style>
